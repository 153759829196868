import React from 'react';

const Border: React.FC = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 768 10" fill="none" {...props}>
    <path fill="#603" d="M0 0h256v10H0z"></path>
    <path fill="#903" d="M256 0h256v10H256z"></path>
    <path fill="#F17C14" d="M512 0h256v10H512z"></path>
  </svg>
);

export default Border;
