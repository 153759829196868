import React from 'react';
import 'twin.macro';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '~components/Layout';
import SEO from '~components/SEO';
import Hero from '~components/Hero';
import Heading, { StyledHeading } from '~components/Heading';
import { IconCard } from '~components/Card';
import ImageList, { imageListStyle } from '~components/ImageList';
import Text from '~components/Text';

import Access from '~components/Svg/Access';
import Bag from '~components/Svg/Bag';
import Check from '~components/Svg/Check';
import Directions from '~components/Svg/Directions';
import Globe from '~components/Svg/Globe';
import Pointer from '~components/Svg/Pointer';

import { VARIANT } from '../../enums';

const EducationPage: React.FC = () => (
  <Layout>
    <SEO title="Education" desc="" pathname="/aspirations/education" />
    <Hero title="Ensure access to education and reduce barriers for all">
      <StaticImage src="../../images/city-school.jpg" alt="" layout="fullWidth" />
    </Hero>
    <div tw="container mb-24">
      <div tw="max-w-5xl">
        <Text>
          We will ensure that Mohawk is the destination of choice for all learners by removing
          barriers encountered at various stages of the educational journey.
        </Text>
      </div>
    </div>
    <ImageList
      title="Strategic Direction"
      items={[
        'Develop a student life-cycle journey map, creating opportunities and removing barriers to support success and persistence from recruitment to graduation.',
        'Expand and deliver education in priority neighbourhoods, deepening our commitment to City School by Mohawk and Early College opportunities with district school boards.',
        'Create more opportunities to expand the City School and Challenge 2025 models, provincially and nationally.',
        'Expand access for Black, Indigenous, People of Colour, and other underrepresented populations, in collaboration with supports from the Foundation, and develop metrics to track progress.',
        'Advance strategies to further expand participation in Women in Trades and Technology, and support persistence, retention and placement in their field.'
      ]}
    >
      <StaticImage
        src="../../images/guiding-a-student-3.jpg"
        alt=""
        layout="fullWidth"
        style={imageListStyle}
      />
    </ImageList>
    <div tw="mb-24 bg-orange text-gray-800 py-12">
      <div tw="container">
        <div tw="justify-between space-x-6 items-center md:(flex)">
          <Pointer tw="h-32 w-32 flex-shrink-0 md:(order-2 h-48 w-48)" />
          <div tw="md:(order-1)">
            <Heading level="h2" tw="mt-0">
              Leadership Outcome
            </Heading>
            <Text tw="text-2xl font-bold">
              We will be the national leader in providing barrier-free access to education by
              directly engaging with our communities through strong industry and community
              partnerships. We will refine and reimagine traditional delivery models to accommodate
              changes in how education, services, supports and employment are delivered.
            </Text>
          </div>
        </div>
      </div>
    </div>
    <div tw="container">
      <div tw="max-w-5xl mb-12">
        <Heading level="h2" tw="text-rose">
          Building on Momentum
        </Heading>
        <Text tw="text-xl font-bold">
          We have a stellar record in expanding access to education and skills training through
          community and industry partnerships, and offer many options to complete programs to ensure
          that all students who want to obtain credentials have pathways to achieve their goals.
        </Text>
        {/* <Heading level="h3">1. Mohawk first</Heading>
        <Text>
          Mohawk was one of the first colleges to offer micro credentials to support credential
          completion.
        </Text>
        <Heading level="h3">2. Mohawk best</Heading>
        <Text>
          To ensure they had access to online classes throughout the pandemic, Mohawk gave
          Indigenous students ‘virtual learning baskets’ with a tablet, internet hub and firestick.
        </Text>
        <Heading level="h3">3. Mohawk best</Heading>
        <Text>
          Our Access Strategy has steadily increased its reach and impact with an innovative range
          of programs and inclusion practices that reduce barriers to access and create pathways to
          education and employment.
        </Text>
        <Heading level="h3">4. Mohawk best</Heading>
        <Text>
          By providing more entry points and pathways to a credential, we have created opportunities
          for students to access new degree programs, including the college’s first stand-alone
          degree in Digital Health.
        </Text>
        <Heading level="h3">5. Mohawk only</Heading>
        <Text>
          The unique City School by Mohawk, which breaks down barriers to education and employment
          by bringing the college directly into Hamilton’s priority neighbourhoods to offer free
          courses, training and employment services, has become a proven model for providing diverse
          entry points to education and training for marginalized adults.
        </Text> */}
      </div>
      <StyledHeading>Aspirations</StyledHeading>
      <div tw="grid gap-10 my-12 md:(grid-cols-2) lg:(grid-cols-3)">
        <IconCard
          variant={VARIANT.ROSE}
          to="/aspirations/truth-reconciliation"
          Icon={<Directions tw="w-12" />}
        >
          Meaningfully advance Truth and Reconciliation
        </IconCard>

        <IconCard variant={VARIANT.RED} to="/aspirations/future-ready" Icon={<Check tw="w-12" />}>
          Enable Future Ready education, research, programs, services and experiences – for
          students, employees and our community
        </IconCard>
        <IconCard
          variant={VARIANT.ROSE}
          to="/aspirations/celebrate-every-person"
          Icon={<Access tw="w-12" />}
        >
          Be a place that honours, values and celebrates the whole of every person
        </IconCard>
        <IconCard
          variant={VARIANT.ROSE}
          to="/aspirations/workforce-development"
          Icon={<Bag tw="w-12" />}
        >
          Lead in workforce development
        </IconCard>
        <IconCard variant={VARIANT.RED} to="/aspirations/climate-change" Icon={<Globe tw="w-12" />}>
          Make a measurable impact on climate change
        </IconCard>
      </div>
    </div>
  </Layout>
);

export default EducationPage;
