import React from 'react';
import tw from 'twin.macro';

import Heading from '~components/Heading';

import ListItem from './ListItem';

interface ImageListProps {
  title: string;
  items: string[];
}

export const imageListStyle = tw`h-full w-full bg-right object-cover opacity-60 xl:absolute xl:inset-0`;

const ImageList: React.FC<ImageListProps> = ({ title, items, children }) => (
  <div tw="relative bg-rose text-white">
    <div tw="h-80 w-full absolute bottom-0 xl:(inset-0 h-full)">
      <div tw="h-full w-full xl:(grid grid-cols-2)">
        <div tw="h-full xl:(relative col-start-2)">
          {children}
          <div
            aria-hidden="true"
            tw="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-rose xl:(inset-y-0 left-0 h-full w-32 bg-gradient-to-r)"
          />
        </div>
      </div>
    </div>
    <div tw="container">
      <div tw="mx-auto xl:(grid grid-cols-2 grid-flow-col-dense gap-x-8)">
        <div tw="relative pt-12 pb-64 sm:(pt-24 pb-64) xl:(col-start-1 pb-24)">
          <Heading level="h2" tw="mt-0">
            {title}
          </Heading>
          <ul tw="list-inside list-disc mt-12 flex flex-col space-y-6">
            {items.map((item) => (
              <li tw="text-lg md:(text-xl)">{item}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default ImageList;
