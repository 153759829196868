import React from 'react';
import 'twin.macro';
import { Link } from 'gatsby';

import Border from '~components/Svg/Border';
import Logo from '~components/Svg/Logo';

interface HeroProps {
  title: string;
}

const Hero: React.FC<HeroProps> = ({ title, children }) => (
  <>
    <div tw="relative mb-12">
      <div tw="absolute z-30 text-white top-0 w-full bg-gradient-to-b from-gray-900 to-transparent pt-12 pb-24">
        <div tw="container">
          <div tw="pr-32">
            <Link to="/">
              <span tw="sr-only">Home</span>
              <Logo tw="h-auto max-w-full w-64 md:w-72 lg:w-96" />
            </Link>
          </div>
        </div>
      </div>
      <div tw="w-full relative z-20">{children}</div>
    </div>
    <div tw="container mb-12">
      <h1 tw="flex flex-col items-start space-y-4 font-semibold text-3xl md:(text-4xl) lg:(text-5xl)">
        <span>{title}</span>
        <Border tw="w-full h-4 max-w-5xl" />
      </h1>
    </div>
  </>
);

export default Hero;
